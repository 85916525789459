import React from "react";
import "./QouickDetails.css";
import { Fade, Slide } from "react-awesome-reveal";

const details = [
	{ number: "25+", title: "عام من الخبرة", delay: "100", direction: "right" },
	{ number: "7+", title: " خدمات", delay: "100", direction: "up" },
	{ number: "3+", title: "فروع", delay: "100", direction: "left" },
];

const QouickDetails = () => {
	return (
		<div className='py-12 flex justify-center items-center flex-wrap gap-y-10 md:gap-y-0'>
			{details?.map((data, idx) => (
				<div
					triggerOnce
					direction={data?.direction}
					delay={data?.delay}
					key={idx}
					className=' flex flex-col justify-center items-center w-1/2 md:w-60 qouic-details-box'>
					<Fade
						delay={1e3}
						damping={1e-1}
						className='text-6xl font-bold text-orang_03 mb-1'>
						{data?.number}
					</Fade>

					<p className='text-2xl text-brown_01 font-normal'>{data?.title}</p>
				</div>
			))}
		</div>
	);
};

export default QouickDetails;
