import React, { useEffect, useState } from "react";

import { MainLogo } from "../../assets/Images";
import Hamburger from "hamburger-react";

import "./Navbar.css";
const navigation = [
	{ name: "من نحن", href: "#about" },
	{ name: "خدماتنا", href: "#services" },
	{ name: "خباراتنا", href: "#skills" },
	{ name: "القيم والأهداف", href: "#goals" },
];
const Navbar = () => {
	const [isScrolled, setIsScrolled] = useState(false);
	const [isOpen, setOpen] = useState(false);

	useEffect(() => {
		// Function to handle scroll event
		const handleScroll = () => {
			const scrolled = window.scrollY > 200;
			setIsScrolled(scrolled);
		};

		// Add scroll event listener when component mounts
		window.addEventListener("scroll", handleScroll);
	}, []);

	return (
		<header>
			<nav
				className={`${
					isScrolled ? " fixed  glass-effect" : "absolute"
				} flex items-center justify-between p-3 lg:px-14 inset-x-0 top-0 z-[99] transition`}
				aria-label='Global'>
				{/* logo */}
				<div className='flex lg:flex-1'>
					<a href='#hero' className='-m-1.5 p-1.5'>
						<img className='h-14 md:h-16 w-auto' src={MainLogo} alt='' />
					</a>
				</div>

				{/* mobile menu icon */}
				<div className='flex lg:hidden'>
					<Hamburger
						toggled={isOpen}
						toggle={setOpen}
						className='h-6 text-brown_01 w-6 -m-2.5 inline-flex items-center justify-center rounded-md p-2.5 '
						aria-hidden='true'
					/>
				</div>

				{/* desktop nav links */}
				<div className='hidden lg:flex '>
					{navigation?.map((item, idx) => (
						<a
							key={idx}
							href={item.href}
							className='text-xl  w-32 py-2 text-center font-medium leading-6 text-brown_01 hover:text-orang_03  page-links '>
							{item.name}
						</a>
					))}
				</div>
			</nav>

			{/* Mobile menu */}
			<div
				as='div'
				className={`${
					isOpen ? "open-menu" : "hidden"
				} lg:hidden fixed top-0 left-0 z-[999] flex-col p-5 h-screen  mobile-menu`}>
				<>
					<div className='flex items-center justify-between'>
						{/* Close menu icon */}
						<Hamburger
							toggled={isOpen}
							toggle={setOpen}
							className='h-6 w-6 -m-2.5 inline-flex items-center justify-center rounded-md p-2.5 text-brown_01'
							aria-hidden='true'
						/>
					</div>
					{/* mobile menu links */}
					<div className='mt-6 flow-root text-center'>
						<div className='-my-6 divide-y flex justify-center items-center h-screen '>
							<div className='space-y-2 py-6'>
								{navigation.map((item, idx) => (
									<a
										onClick={() => setOpen(!isOpen)}
										key={idx}
										href={item.href}
										className='-mx-3 block page-links rounded-lg px-3 py-3 text-2xl font-medium leading-7  text-orang_01 hover:bg-gray-50'>
										{item.name}
									</a>
								))}
							</div>
						</div>
					</div>
				</>
			</div>
		</header>
	);
};

export default Navbar;
