import React from "react";
import "./Experiences.css";
import { MdVerified } from "react-icons/md";
import { Fade } from "react-awesome-reveal";
import { SiSololearn } from "react-icons/si";
import { GiClockwork } from "react-icons/gi";
import {
	AiFillSlackCircle,
	AiFillSliders,
	AiFillAlipayCircle,
	AiFillCodeSandboxCircle,
	AiFillSecurityScan,
	AiOutlineCodepenCircle,
} from "react-icons/ai";

const experiencesData = [
	{
		delay: "100",
		direction: "right",
		title:
			"حاصل على شهادة البكالوريوس في تخصص الهندسة الميكانيكية من جامعة الملك سعود - الرياض.",
		icon: <SiSololearn className=' text-3xl text-yellow_02 mx-auto mb-4' />,
	},
	{
		delay: "100",
		direction: "up",
		title:
			"التحق بالعمل لدى أكبر شركات النفط في العالم شركة أرامكو السعودية عام ( 1991 ) .",
		icon: <GiClockwork className=' text-3xl text-yellow_02 mx-auto mb-4' />,
	},
	{
		delay: "100",
		direction: "bottom",
		title:
			"عمل رئيساً لقسم صيانة قاعدة الملك فيصل الجوية لتعبئة الوقود التابعة لأرامكو السعودية (1991-1994).",
		icon: (
			<AiFillSlackCircle className=' text-3xl text-yellow_02 mx-auto mb-4' />
		),
	},
	{
		delay: "100",
		direction: "left",
		title:
			"عمل مهندساً لمشاريع بقسم التخطيط والصيانة في شركة أرامكو السعودية (1995 - 2007).",
		icon: (
			<AiOutlineCodepenCircle className=' text-4xl text-yellow_02 mx-auto mb-4' />
		),
	},
	{
		delay: "100",
		direction: "up",
		title:
			"أشرف على تنفيذ عدة مشاريع كبيرة في عدة مناطق منها توسعة محطة ضباء وجيزان التابعة لشركة أرامكو السعودية.",
		icon: (
			<AiFillAlipayCircle className=' text-4xl text-yellow_02 mx-auto mb-4' />
		),
	},
	{
		delay: "100",
		direction: "right",
		title: "عمل في راس تنوره كمهندس صيانة مشاريع خزانات الوقود (2005).",
		icon: (
			<AiFillCodeSandboxCircle className=' text-4xl text-yellow_02 mx-auto mb-4' />
		),
	},
	{
		delay: "100",
		direction: "left",
		title: "عمل مهندس أول في قسم التخطيط وصيانة الخزانان في جدة (2007 - 2012).",
		icon: <AiFillSliders className=' text-3xl text-yellow_02 mx-auto mb-4' />,
	},
	{
		delay: "100",
		direction: "bottom",
		title:
			"عمل رئيساً لقسم التخطيط والصيانة لخزانات توزيع المنطقة الغربية (2008-2019).",
		icon: (
			<AiFillSecurityScan className=' text-4xl text-yellow_02 mx-auto mb-4' />
		),
	},
];
const Experiences = () => {
	return (
		<div
			id='skills'
			className='relative experiences isolate overflow-hidden  py-14 md:py-16 experiences-section'>
			<div className='overlay' />

			<div className='mx-auto max-w-7xl px-6 lg:px-8 z-10'>
				<div className='mx-auto max-w-2xl lg:mx-0 mb-5 md:mb-10'>
					<Fade
						delay={1e3}
						damping={1e-1}
						className='text-lg font-medium mb-3 border-style  w-max text-yellow_02'>
						خبرة لأكثر من 26 عام بين يديك
					</Fade>
					<h2 className='text-2xl z-10 relative font-semibold tracking-tight text-orang_02 sm:text-4xl'>
						خبرات مؤسس الشركة
					</h2>
				</div>

				<div className='experiences-wrapper z-10 relative'>
					{experiencesData.map((data, idx) => (
						<Fade
							triggerOnce
							direction={data?.direction}
							delay={data?.delay}
							className=' experiences-box group rounded-md md:rounded-xl text-lg leading-8 text-brown_01 text-center md:text-right'>
							<p key={idx}>
								<div>{data?.icon}</div>
								{data?.title}
								<MdVerified className='opacity-icon group-hover:text-orang_01 ' />
							</p>
						</Fade>
					))}
				</div>
			</div>
		</div>
	);
};

export default Experiences;
