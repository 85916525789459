import React from "react";
import "./Hero.css";

import { FiArrowDown } from "react-icons/fi";

import { Bounce, Fade, Slide } from "react-awesome-reveal";
import { HeroImg } from "../../assets/Images";

const Hero = () => {
	return (
		<div
			id='hero'
			className='relative isolate px-6  md:py-0 pt-36 pb-16  lg:px-8 '>
			<div
				className='absolute  inset-x-0 -top-40 -z-10 transform-gpu overflow-hidden blur-3xl sm:-top-80'
				aria-hidden='true'>
				<div
					className='relative left-[calc(50%-11rem)] aspect-[1155/678] w-[36.125rem] -translate-x-1/2 rotate-[30deg] bg-gradient-to-tr from-[#fcd482] to-[#f4a41c] opacity-30 sm:left-[calc(50%-30rem)] sm:w-[72.1875rem]'
					style={{
						clipPath:
							"polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)",
					}}
				/>
			</div>
			{/* Content */}
			<div className='mx-auto max-w-[95%] flex flex-col md:flex-row justify-center items-center h-screen'>
				<div className='text-right md:w-2/3 w-full  relative z-10'>
					<Slide triggerOnce direction={"right"}>
						<h1 className='text-4xl   font-bold tracking-tight text-brown_01 sm:text-6xl leading-[1.2]'>
							<span className=' text-orang_02 petro-bark'>بتـرو بـارك</span> هى
							علامـة تجاريـة مملوكة لشـركة نفـط الجزيـرة المحـدودة.
						</h1>
					</Slide>

					<Slide
						triggerOnce
						direction={"up"}
						className='md:w-5/12  w-full block md:hidden'>
						<img src={HeroImg} alt='' />
					</Slide>
					<Fade
						className='mt-6 text-xl leading-8 text-yellow_01 '
						delay={1e3}
						damping={1e-1}>
						خدمات بترولية ... تليك بكم
					</Fade>

					<div className='mt-10 flex items-center justify-start gap-x-6'>
						<Bounce
							triggerOnce
							className=' rounded-ss-md hover:rounded-md hover:rounded-ss-none about-us-btn cursor-pointer bg-orang_01 px-8 py-2.5 text-xl font-medium text-white  focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600'>
							<a href='#about'>من نحن</a>
						</Bounce>

						<a
							href='#services'
							className='text-xl font-medium flex items-center gap-1 custom-transition group leading-6 text-yellow_01 hover:text-orang_01'>
							خدماتنا{" "}
							<span>
								<FiArrowDown className='animate-bounce' />
							</span>
						</a>
					</div>
				</div>

				<Slide
					triggerOnce
					direction={"up"}
					className='md:w-5/12  w-full md:block hidden'>
					<img className=' max-w-[90%]' src={HeroImg} alt='' />
				</Slide>
			</div>

			<div
				className='absolute inset-x-0 top-[calc(100%-13rem)] -z-10 transform-gpu overflow-hidden blur-3xl sm:top-[calc(100%-30rem)]'
				aria-hidden='true'>
				<div
					className='relative left-[calc(50%+3rem)] aspect-[1155/678] w-[36.125rem] -translate-x-1/2 bg-gradient-to-tr  from-[#fcd482] to-[#f4a41c] opacity-30 sm:left-[calc(50%+36rem)] sm:w-[72.1875rem]'
					style={{
						clipPath:
							"polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)",
					}}
				/>
			</div>
		</div>
	);
};

export default Hero;
