import React from "react";

import "./Footer.css";

import { CiMobile1, CiLocationOn } from "react-icons/ci";
import { FiPhoneCall, FiMap } from "react-icons/fi";
import { Img2, Img3, MainLogo } from "../../assets/Images";
import { GrDocumentVerified } from "react-icons/gr";
import { MdOutlineMarkEmailRead } from "react-icons/md";
import { HiOutlineClipboardDocumentList } from "react-icons/hi2";

import { BsSignpostSplit } from "react-icons/bs";
import { FaHeadphonesAlt } from "react-icons/fa";

const Footer = () => {
	return (
		<footer className='footer text-center py-8 px-14 relative'>
			<div className='overlay' />
			<img className='footer-img2 z-10' src={Img2} alt='' />
			<section className='flex flex-col md:flex-row justify-between items-start'>
				{/* logo and name */}
				<div className='text-right mb-5 md:mb-0 z-10'>
					<a href='#hero' className=''>
						<img className='h-12 md:h-14 w-auto mb-2' src={MainLogo} alt='' />
					</a>
					<h4 className='text-md font-normal text-brown_01 '>
						شركة نفط الجزيرة
					</h4>
				</div>

				{/* contact info*/}
				<div className='text-right mb-5 md:mb-0 z-10'>
					<h4 className='mb-2 md:mb-3  text-brown_01 font-medium text-xl footer-title relative'>
						تواصل معنا
					</h4>

					<ul>
						<li className='mb-2'>
							<span className='flex justify-start items-center gap-1 '>
								<CiMobile1 className='text-xl text-orang_01' />
								<p className=' text-brown_01 text-md font-normal'>
									رقم الجوال :{" "}
									<a
										target='_blank'
										rel='noreferrer'
										className='hover:text-yellow_02 hover:underline
									custom-transition'
										href='tel:+9660554454801'>
										0554454801
									</a>
								</p>
							</span>
						</li>

						<li className='mb-2'>
							<span className='flex justify-start items-center gap-1 '>
								<FiPhoneCall className='text-xl text-orang_01' />
								<p className=' text-brown_01 text-md font-normal'>
									{" "}
									الرقم الثابت:{" "}
									<a
										target='_blank'
										className='hover:text-yellow_02 hover:underline
									custom-transition'
										rel='noreferrer'
										href='tel:+9660122346668'>
										0122346668
									</a>
								</p>
							</span>
						</li>

						<li className='mb-2'>
							<span className='flex justify-start items-center gap-1 '>
								<FaHeadphonesAlt className='text-xl text-orang_01' />
								<p className=' text-brown_01 text-md font-normal'>
									{" "}
									الرقم الموحد :
									<a
										target='_blank'
										className='hover:text-yellow_02 hover:underline
									custom-transition'
										rel='noreferrer'
										href='tel:+966920013259'>
										920013259
									</a>
								</p>
							</span>
						</li>

						<li className=''>
							<span className='flex justify-start items-center gap-1'>
								<MdOutlineMarkEmailRead className='text-xl text-orang_01' />
								<p className=' text-brown_01 text-md font-normal'>
									الايميل الرسمي: {"  "}
									<a
										target='_blank'
										rel='noreferrer'
										className='hover:text-yellow_02 hover:underline custom-transition'
										href='mailto:Info@petropark.sa'>
										Info@petropark.sa
									</a>
								</p>
							</span>
						</li>
					</ul>
				</div>

				{/* address */}
				<div className='text-right mb-5 md:mb-0 z-10'>
					<h4 className='mb-2 md:mb-3  text-brown_01 font-medium text-xl footer-title relative'>
						معلومات
					</h4>

					<ul>
						<li className='mb-2'>
							<span className='flex justify-start items-center gap-1 '>
								<CiLocationOn className='text-xl text-orang_01' />
								<p className=' text-brown_01 text-md font-normal'>
									المملكة العربية السعودية - جـــدة{" "}
								</p>
							</span>
						</li>

						<li className='mb-2'>
							<span className='flex justify-start items-center gap-1 '>
								<BsSignpostSplit className='text-xl text-orang_01' />
								<p className=' text-brown_01 text-md font-normal'>
									{" "}
									الرمز البريدي : <span>23414</span>
								</p>
							</span>
						</li>

						<li className='mb-2'>
							<span className='flex justify-start items-center gap-1 '>
								<GrDocumentVerified className='text-xl text-orang_01' />
								<p className=' text-brown_01 text-md font-normal'>
									سجـــل تجــارى : <span>4030431576</span>
								</p>
							</span>
						</li>

						<li>
							<span className='flex justify-start items-center gap-1 '>
								<HiOutlineClipboardDocumentList className='text-xl text-orang_01' />
								<p className=' text-brown_01 text-md font-normal'>
									رقم العضويه : <span>420506</span>
								</p>
							</span>
						</li>
					</ul>
				</div>

				{/* address */}
				<div className='text-right z-10'>
					<h4 className='mb-2 md:mb-3  text-brown_01 font-medium text-xl footer-title relative'>
						عناوين الفروع
					</h4>

					<ul>
						<li className='mb-2'>
							<span className='flex justify-start items-center gap-1 '>
								<FiMap className='text-xl text-orang_01' />
								<a
									target='_blank'
									rel='noreferrer'
									href='https://www.google.com/maps?q=21.5102260,39.2016220&entry=gps&lucs=,47071704,47069508,47084304,94206605&g_ep=CAISDTYuMTAxLjMuNDE1NjAYACDXggMqJCw0NzA3MTcwNCw0NzA2OTUwOCw0NzA4NDMwNCw5NDIwNjYwNUICU0E%3D&g_st=iw'
									className=' text-brown_01 text-md font-normal hover:text-yellow_02 hover:underline custom-transition'>
									المكتب الرئيسي- جدة
								</a>
							</span>
						</li>

						<li className='mb-2'>
							<span className='flex justify-start items-center gap-1 '>
								<FiMap className='text-xl text-orang_01' />
								<a
									target='_blank'
									rel='noreferrer'
									href='https://www.google.com/maps?q=CGR9+5X+%D9%85%D8%AD%D8%B7%D8%A9+%D8%A8%D9%86%D8%B2%D9%8A%D9%86+%D8%A8%D8%AA%D8%B1%D9%88+%D8%A8%D8%A7%D8%B1%D9%83+%D8%AA%D8%AD%D8%AA+%D8%A7%D9%84%D8%A7%D9%86%D8%B4%D8%A7%D8%A1%D8%8C+%D8%A7%D9%84%D9%85%D8%B7%D8%B9%D9%86+87743&ftid=0x15e2cbc89d1cceaf:0x632173dc1e9a95c7&entry=gps&lucs=,47071704,47069508,47084304,94206605&g_ep=CAISDTYuMTAxLjMuNDE1NjAYACDXggMqJCw0NzA3MTcwNCw0NzA2OTUwOCw0NzA4NDMwNCw5NDIwNjYwNUICU0E%3D&g_st=iw'
									className=' text-brown_01 text-md font-normal hover:text-yellow_02 hover:underline custom-transition'>
									محطة بيش
								</a>
							</span>
						</li>

						<li className='mb-2'>
							<span className='flex justify-start items-center gap-1 '>
								<FiMap className='text-xl text-orang_01' />
								<a
									target='_blank'
									rel='noreferrer'
									href='https://www.google.com/maps?q=FH8M+62G%D8%8C+%D8%A7%D9%84%D9%88%D8%A8%D8%B1%D8%A9%D8%8C+%D8%A7%D9%84%D9%85%D8%AF%D9%8A%D9%86%D8%A9+%D8%A7%D9%84%D9%85%D9%86%D9%88%D8%B1%D8%A9+42371&ftid=0x15bdbf397bb3c92b:0xdb3716bbd7187632&entry=gps&lucs=,47071704,47069508,47084304,94206605&g_ep=CAISDTYuMTAxLjMuNDE1NjAYACDXggMqJCw0NzA3MTcwNCw0NzA2OTUwOCw0NzA4NDMwNCw5NDIwNjYwNUICU0E%3D&g_st=iw'
									className=' text-brown_01 text-md font-normal hover:text-yellow_02 hover:underline custom-transition'>
									محطة المدينة
								</a>
							</span>
						</li>
					</ul>
				</div>
			</section>

			<img className='footer-img3 z-10' src={Img3} alt='' />
		</footer>
	);
};

export default Footer;
