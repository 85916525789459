import React from "react";
import { FaGasPump } from "react-icons/fa";
import { IoSettingsOutline } from "react-icons/io5";
import "./AboutUs.css";
import { AboutIMg } from "../../assets/Images";
import { PiGasCan } from "react-icons/pi";
import { Fade, Slide } from "react-awesome-reveal";
const AboutUs = () => {
	return (
		<div
			id='about'
			className='relative isolate overflow-hidden  py-14 md:py-16 mb-12'>
			<FaGasPump className='moveupdown-animation text-[60px] absolute left-36 top-[21rem] opacity-[0.1]  text-yellow_02' />
			<PiGasCan className='moveupdown-animation gas-can-icon text-[60px] absolute right-[43rem] top-80 opacity-[0.1]  text-yellow_02' />
			<IoSettingsOutline className='rotate-animation text-[60px] absolute right-36 top-28 opacity-[0.1] text-yellow_02' />
			<div className='absolute inset-0 -z-10 h-full w-full object-cover object-right md:object-center' />

			<div className='mx-auto max-w-[95%] px-6 lg:px-8 flex justify-start items-center'>
				<div className=' md:w-1/2 w-full  hidden md:block'>
					<Fade
						delay={1e3}
						damping={1e-1}
						className='text-lg font-medium mb-3 border-style  w-max text-yellow_02'>
						شركة نفط الجزيرة المحدودة
					</Fade>

					<Slide
						triggerOnce
						direction={"right"}
						className='text-2xl font-semibold tracking-tight text-orang_02 sm:text-4xl'>
						من نحن
					</Slide>
					<Slide triggerOnce direction={"left"}>
						<img src={AboutIMg} alt='' />
					</Slide>
				</div>
				<div className='  md:w-1/2 w-full '>
					<div className='  md:hidden block'>
						<Fade
							delay={1e3}
							damping={1e-1}
							className='text-lg font-medium mb-3 border-style  w-max text-yellow_02'>
							شركة نفط الجزيرة المحدودة
						</Fade>

						<Slide
							triggerOnce
							direction={"right"}
							className='text-2xl font-semibold tracking-tight text-orang_02 sm:text-4xl'>
							من نحن
						</Slide>
					</div>
					<p className='mt-6 text-lg leading-8 text-brown_01 text-right md:hidden block mb-2'>
						<span className=' text-orang_02'>بترو بارك</span> متخصصة فـي مجال
						نقل وتعبئــة البتــرول و الوقــود بأعلــى جــودة واحترافيــة، مقرها
						المملكــة العربيـة السـعودية، حيـث تأسسـت{" "}
						<span className=' text-orang_02'>
							شـركة نفـط الجزيـرة المحـدودة
						</span>{" "}
						عـام 2021م ، ورغـم نشــئتها الحديثــة إلا أنهــا تمتلــك كــوادر
						وخبــرات تمتــد لســنوات طويلــة.
					</p>

					<div className='mx-auto mt-10 max-w-2xl md:mx-0 md:max-w-none md:hidden block'>
						<Slide triggerOnce direction={"left"}>
							<img src={AboutIMg} alt='' />
						</Slide>
					</div>
					<p className='mt-6 text-lg leading-8 text-brown_01 text-right hidden md:block mb-2'>
						<span className=' text-orang_02'>بترو بارك</span> متخصصة فـي مجال
						نقل وتعبئــة البتــرول و الوقــود بأعلــى جــودة واحترافيــة، مقرها
						المملكــة العربيـة السـعودية، حيـث تأسسـت{" "}
						<span className=' text-orang_02'>
							شـركة نفـط الجزيـرة المحـدودة
						</span>{" "}
						عـام 2021م ، ورغـم نشــئتها الحديثــة إلا أنهــا تمتلــك كــوادر
						وخبــرات تمتــد لســنوات طويلــة.
					</p>
					<p className=' text-lg leading-8 text-brown_01 text-right mb-2'>
						وهــو مــا يضمــن تقديــم كافــة خدماتهــا وفــق أعلــى المعاييــر
						والمواصفــات، فهــي تقــدم كافــة خدمــات نقــل وتعبئــة الوقــود
						بواســطة أســطول مخصــص للنقــل وبيــع الوقــود بالتجزئــة بواســطة
						أحــدث محطــات الوقــود، مــع توفيــر خدمــات الصيانــة والإصــاح
						وتغييــر الزيــوت والاطــارات لمختلــف المركبــات.
					</p>
					<p className=' text-lg leading-8 text-brown_01 text-right'>
						وتتميــز الشــركة بدورهــا فــي إنشــاء محطــات الوقــود بأعلــى
						المعاييــر والمواصفــات المعتمــدة محليًــا وعالميًــا وفــق رؤيــة
						طموحــة تطمــح إليهــا الشــركة، وهــدف الشــركة الدائــم هــو
						إنشــاء وتطويــر وتأهيــل محطــات الوقــود وفقاً لرؤيــة المملكــة
						2030.
					</p>
				</div>
			</div>

			{/* */}
			<div
				className='absolute inset-x-0 top-[calc(100%-13rem)] -z-10 transform-gpu overflow-hidden blur-3xl sm:top-[calc(100%-30rem)]'
				aria-hidden='true'>
				<div
					className='relative left-[calc(50%+3rem)] aspect-[1155/678] w-[36.125rem] -translate-x-1/2 bg-gradient-to-tr  from-[#fcd482] to-[#f4a41c] opacity-30 sm:left-[calc(50%+36rem)] sm:w-[72.1875rem]'
					style={{
						clipPath:
							"polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)",
					}}
				/>
			</div>
		</div>
	);
};

export default AboutUs;
