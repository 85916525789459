import "./App.css";
import AboutUs from "./Components/AboutUs/AboutUs";
import Experiences from "./Components/Experiences/Experiences";
import Features from "./Components/Features/Features";
import Footer from "./Components/Footer/Footer";
import Hero from "./Components/Hero/Hero";
import { Navbar } from "./Components/Navbar";
import QouickDetails from "./Components/QouickDetails/QouickDetails";
import Services from "./Components/Services/Services";

function App() {
	return (
		<div className='App'>
			{/* nav bar*/}
			<Navbar />

			{/* Hero */}
			<Hero />

			{/* QouickDetails */}
			<QouickDetails />

			{/* AboutUs */}
			<AboutUs />

			{/* Services */}
			<Services />

			{/* Experiences */}
			<Experiences />

			{/* Features */}
			<Features />

			{/* Footer */}
			<Footer />
		</div>
	);
}

export default App;
