import React from "react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/navigation";
import "./Services.css";

// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, FreeMode, Navigation } from "swiper/modules";
import {
	BuildImage,
	CarServices,
	ElectricCar,
	GasStation,
	GasTank,
	GasTruck,
	Market,
} from "../../assets/Images";
import { Fade } from "react-awesome-reveal";

const servicesData = [
	{
		title: "إنشاء المحطات",
		content: `تعمــل الشــركة علــى إنشــاء وتطويــر وتأهيــل محطــات الوقــود علــى أعلــى معاييــر الجــودة.`,
		icon: BuildImage,
	},
	{
		title: "محطات الوقود",
		content: `تقــدم الشــركة خدمــات إدارة وتشــغيل وصيانــة
محطــات الوقــود ومرافقهــا.`,
		icon: GasStation,
	},
	{
		title: "نقل الوقود",
		content: `توفــر الشــركة خدمــات نقــل الوقــود بواســطة
أســطول مــن الناقــات الحديثــة والمجهــزة.`,
		icon: GasTruck,
	},
	{
		title: "تعبئة وبيع الوقود",
		content: `توفــر الشــركة خدمــات تعبئــة وبيــع كافــة أنــواع
الوقــود بالتجزئــة للمركبــات والشــاحنات .`,
		icon: GasTank,
	},
	{
		title: "شحن المركبات الكهربائية",
		content: `توفر الشركة خدمة شحن المركبات الكهربائية.`,
		icon: ElectricCar,
	},
	{
		title: "خدمات المركبات",
		content: `توفــر الشــركة خدمــات صيانــة وإصــاح المركبــات
وتغييــر الزيــوت وبيــع الإطــارات.`,
		icon: CarServices,
	},
	{
		title: " بيع المواد الغذائية",
		content: `توفــر الشــركة إمكانيــة بيــع مختلــف المــواد
الغذائيــة التــي يحتــاج إليهــا العمــاء.`,
		icon: Market,
	},
];

const Services = () => {
	return (
		<div id='services' className='services-section'>
			{/* content */}
			<div className='services-wrapper'>
				<div className='absolute text-center services-text'>
					<Fade
						delay={1e3}
						damping={1e-1}
						className=' text-2xl font-normal text-white mb-2'>
						أفضل الخدمات
					</Fade>
					<h2 className='text-4xl font-medium text-white '>
						خدمات ذات{" "}
						<span className='italic text-orang_01 font-semibold'>
							جودة ثابتة
						</span>
					</h2>
				</div>
				<div className='services-box'>
					<Swiper
						slidesPerView={1}
						spaceBetween={10}
						freeMode={true}
						navigation={true}
						loop={true}
						dir='rtl'
						autoplay={{
							delay: 2100,
							disableOnInteraction: false,
						}}
						breakpoints={{
							768: {
								slidesPerView: 3,
								spaceBetween: 20,
							},
						}}
						modules={[FreeMode, Navigation, Autoplay]}
						className='services-slides h-auto md:h-[280px]'>
						{servicesData?.map((service, idx) => (
							<SwiperSlide key={idx}>
								<Fade direction='up' triggerOnce>
									<div className='services-slide h-[260px] '>
										<div className='flex justify-start gap-3'>
											<div className='icon-box flex justify-center items-center'>
												<img src={service?.icon} alt='' />
											</div>
										</div>
										<h3 className=' text-[22px] text-brown_01 font-medium mb-1'>
											{service?.title}
										</h3>
										<p className=' text-lg text-yellow_02 font-normal '>
											{" "}
											{service?.content}{" "}
										</p>
									</div>
								</Fade>
							</SwiperSlide>
						))}
					</Swiper>
				</div>
			</div>
		</div>
	);
};

export default Services;
