import React from "react";

// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, FreeMode } from "swiper/modules";

// Import Swiper styles
import "swiper/css";
import "swiper/css/free-mode";
import "./Features.css";

// Icons
import { TiArrowLeftOutline } from "react-icons/ti";
import { Goals, Img1, Img2, OurMessage, OurVision } from "../../assets/Images";
import { Fade, Slide } from "react-awesome-reveal";

const featuresData = [
	{
		title: "أهدافنا",
		content:
			"إنشــاء وتطويــر وتأهيــل محطــات الوقــود ورفــع مســتوى الخدمــات التــي تقدمهــا محطــات الوقــود فــي المملكــة لعملائهــا ، لتتوافــق مــع رؤيــة المملكــة 2030 ، ولتلبــي الاحتياجــات والمتطلبــات للعمــاء وفــق أعلــى معاييــر الجــودة والاحترافيــة",
		icon: Goals,
	},
	{
		title: "رسالتنا",
		content:
			"الالتــزام الكامــل بالمواصفــات ومعاييــر الجــودة العالميــة مــع التزامنــا الكامــل بمعاييــر الأمــن والســامة عنــد تنفيــذ الخدمــات مــع مواكبــة احتياجــات الســوق بوجــه عــام والعمــاء بشــكل خــاص ، وأن تصــل رؤيــة الشــركة الطموحــة فــي عــام 2030 بتطويــر جميــع منســوبيها بمــا يتوافــق مــع طموحــات الشــركة .",
		icon: OurMessage,
	},
	{
		title: "رؤيتنا",
		content:
			"تسـعى بتـرو بـارك لتتبـوء مكانـة رياديـة فـي تقديــم كافــة الخدمــات البتروليــة ، وذلــك عـن طريـق تقديـم حلـول متكاملـة تجمـع بيـن الأداء المهنـي والجـودة العاليـة بأحـدث أنــواع التكنولوجيــا ، والعمــل علــى تطبيــق جميـع الحلـول لوقـود منخفـض الانبعاثـات والتــي تتماشــى مــع رؤيــة الصحــة العالميــة لبيئــة خاليــة مــن التلــوث ، وذلــك بتوفيــر خدمــة الشــحن الكهربائــي للســيارات الكهربائيــة.",
		icon: OurVision,
	},

	{
		title: "أهدافنا",
		content:
			"إنشــاء وتطويــر وتأهيــل محطــات الوقــود ورفــع مســتوى الخدمــات التــي تقدمهــا محطــات الوقــود فــي المملكــة لعملائهــا ، لتتوافــق مــع رؤيــة المملكــة 2030 ، ولتلبــي الاحتياجــات والمتطلبــات للعمــاء وفــق أعلــى معاييــر الجــودة والاحترافيــة",
		icon: Goals,
	},
	{
		title: "رسالتنا",
		content:
			"الالتــزام الكامــل بالمواصفــات ومعاييــر الجــودة العالميــة مــع التزامنــا الكامــل بمعاييــر الأمــن والســامة عنــد تنفيــذ الخدمــات مــع مواكبــة احتياجــات الســوق بوجــه عــام والعمــاء بشــكل خــاص ، وأن تصــل رؤيــة الشــركة الطموحــة فــي عــام 2030 بتطويــر جميــع منســوبيها بمــا يتوافــق مــع طموحــات الشــركة .",
		icon: OurMessage,
	},
	{
		title: "رؤيتنا",
		content:
			"تسـعى بتـرو بـارك لتتبـوء مكانـة رياديـة فـي تقديــم كافــة الخدمــات البتروليــة ، وذلــك عـن طريـق تقديـم حلـول متكاملـة تجمـع بيـن الأداء المهنـي والجـودة العاليـة بأحـدث أنــواع التكنولوجيــا ، والعمــل علــى تطبيــق جميـع الحلـول لوقـود منخفـض الانبعاثـات والتــي تتماشــى مــع رؤيــة الصحــة العالميــة لبيئــة خاليــة مــن التلــوث ، وذلــك بتوفيــر خدمــة الشــحن الكهربائــي للســيارات الكهربائيــة.",
		icon: OurVision,
	},
];

const principles = [
	{
		content:
			"الالتـزام بالقيـم والأخـاق الإسـامية التـي ترشـدنا علـى الصـدق والأمانـة والنزاهـة فـي جميـع تعاملاتنـا المحليـة والعالميـة .",
		icon: <TiArrowLeftOutline className='text-orang_01' />,
	},
	{
		content:
			"الالتزام بمفهوم الجودة الشاملة في كافة أعمالنا وخدماتنا لتحقيق رغبة عملائنا .",
		icon: <TiArrowLeftOutline className='text-orang_01' />,
	},
	{
		content:
			"تكوين علاقات طويلة الأمد مع عملائنا من خلال كسب ثقتهم بخدمات بترو بارك.",
		icon: <TiArrowLeftOutline className='text-orang_01' />,
	},
	{
		content: "الالتزام بمعايير سياسة الأمن والسلامة.",
		icon: <TiArrowLeftOutline className='text-orang_01' />,
	},
];

const Features = () => {
	return (
		<div
			id='goals'
			className=' features-box py-10 md:pt-0 !pt-0 md:py-8 relative'>
			<div className='overlay' />
			<div className='mx-auto features p-8 max-w-7xl  px-6 lg:px-8 flex justify-start flex-col md:flex-row items-start z-50 gap-3'>
				<img className='features-img2 z-10' src={Img2} alt='' />
				<div className='z-10 w-full md:w-[44%] mb-5 md:mb-10 '>
					<Fade
						delay={1e3}
						damping={1e-1}
						className='text-lg font-medium mb-3 border-style  w-max text-yellow_02'>
						في عملنا نفخر بالجودة ونقدمها ونبني غدًا
					</Fade>
					<Slide
						triggerOnce
						direction={"right"}
						className='text-2xl font-semibold tracking-tight text-orang_02 sm:text-4xl mb-8'>
						قيمنا التي نعتز بها
					</Slide>

					<div className='our-princibles'>
						{principles?.map((item, idx) => (
							<Fade
								cascade
								damping={1}
								triggerOnce
								direction={"up"}
								delay={idx * 2}
								duration={900}>
								<div
									key={idx}
									className='flex justify-start items-baseline gap-1 mb-2'>
									<span>{item?.icon}</span>
									<p className=' text-md font-normal text-brown_01'>
										{item?.content}
									</p>
								</div>
							</Fade>
						))}
					</div>
				</div>

				<div className='z-10  w-full md:w-[56%]'>
					<Swiper
						slidesPerView={1}
						spaceBetween={12}
						freeMode={true}
						loop={true}
						dir='rtl'
						pagination={{
							dynamicBullets: true,
						}}
						autoplay={{
							delay: 2500,
							disableOnInteraction: false,
						}}
						breakpoints={{
							768: {
								slidesPerView: 2,
							},
						}}
						modules={[Autoplay, FreeMode]}
						className='features-slides h-auto md:h-[380px]'>
						{featuresData?.map((feat, idx) => (
							<SwiperSlide key={idx}>
								<Fade direction='left' triggerOnce>
									<div className='features-slide relative h-[320px] md:h-[360px]'>
										<img
											className='h-12 md:h-14 m-auto'
											src={feat?.icon}
											alt=''
										/>
										<h5 className='text-xl mb-2 md:text-2xl font-medium md:font-normal text-brown_01 '>
											{feat?.title}
										</h5>
										<p className='text-md text-gray_02 font-normal'>
											{feat?.content}
										</p>
									</div>
								</Fade>
							</SwiperSlide>
						))}
					</Swiper>
				</div>
				<img className='features-img1 z-10' src={Img1} alt='' />
			</div>
		</div>
	);
};

export default Features;
